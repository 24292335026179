import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { NO, YES } from '@engined/client/locales.js';
import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import SelectFilter, { Option } from '@engined/client/components/table/SelectFilter.js';
import React, { useMemo } from 'react';

const BooleanFilter: FilterComponent<any, boolean> = (props) => {
  const { t } = useLocale();

  const options = useMemo(
    (): Option[] => [
      { label: t(YES), value: 'true' },
      { label: t(NO), value: 'false' },
    ],
    [t],
  );

  return <SelectFilter {...props} options={options} />;
};

BooleanFilter.displayName = 'BooleanFilter';

BooleanFilter.filterToGraphQLVariable = (value) => value === 'true';

export default React.memo(BooleanFilter);
