import { useMutation } from '@apollo/client/index.js';
import IconButtonWithLoading from '@asaprint/asap/components/IconButtonWithLoading.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
} from '@asaprint/asap/components/ReactTable.js';
import BooleanCell from '@asaprint/asap/components/tables/BooleanCell.js';
import BooleanFilter from '@asaprint/asap/components/tables/BooleanFilter.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import SelectFilter, { Option } from '@engined/client/components/table/SelectFilter.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import {
  ADMIN_USERS_COLUMN_ACTIVE,
  ADMIN_USERS_COLUMN_EMAIL,
  ADMIN_USERS_COLUMN_FIRST_NAME,
  ADMIN_USERS_COLUMN_LAST_NAME,
  ADMIN_USERS_COLUMN_ROLE,
  ADMIN_USERS_COLUMN_USERNAME,
  ADMIN_USERS_NEW_USER,
  ADMIN_USERS_TITLE,
  COLUMN_ACTIONS,
  TOOLTIP_ACTIVATE,
  TOOLTIP_DEACTIVATE,
  TOOLTIP_EDIT,
} from '@asaprint/asap/locales/client.js';
import { ADMIN_USERS_CREATE_ROUTE, ADMIN_USERS_EDIT_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { Users_Load, Users_SetActive } from '@asaprint/asap/routes/__authenticated/admin/users/index.graphql';
import {
  StringFilter as SchemaStringFilter,
  Users_LoadQuery,
  Users_SetActiveMutation,
  Users_SetActiveMutationVariables,
  Users_UserFragment,
} from '@asaprint/asap/schema.client.types.js';
import { Permission, Role } from '@asaprint/common/access.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add, Edit, LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Column, TableState } from 'react-table';

const logger = getLogger('@asap/routes/admin/users/index');

const UsersReactTable = TGraphQLReactTable as unknown as React.NamedExoticComponent<
  GraphQLReactTableProps<Users_UserFragment>
>;

interface OwnProps {}

type Props = OwnProps;

const defaultSortBy: TableState['sortBy'] = [{ id: 'username', desc: false }];

const emptyArray = [];
const mapData = (data: Users_LoadQuery) => data?.users?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: Users_LoadQuery) => data?.users?.pageInfo.count || 0;

const Page: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();
  const columns = useMemo<Column<Users_UserFragment>[]>(
    () => [
      {
        Header: t(COLUMN_ACTIONS),
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row }) => (
          <CenteredCell>
            <Tooltip title={t(TOOLTIP_EDIT)} placement="top">
              <IconButton size="small" component={RouterLink} to={url(ADMIN_USERS_EDIT_ROUTE, { id: value })}>
                <Edit />
              </IconButton>
            </Tooltip>
            <SetActiveButton user={row.original} />
          </CenteredCell>
        ),
      },
      {
        Header: t(ADMIN_USERS_COLUMN_USERNAME),
        accessor: 'username',
        width: 150,
      },
      {
        Header: t(ADMIN_USERS_COLUMN_FIRST_NAME),
        accessor: 'firstName',
        width: 150,
      },
      {
        Header: t(ADMIN_USERS_COLUMN_LAST_NAME),
        accessor: 'lastName',
        width: 150,
      },
      {
        Header: t(ADMIN_USERS_COLUMN_EMAIL),
        accessor: 'email',
        width: 150,
      },
      {
        Header: t(ADMIN_USERS_COLUMN_ROLE),
        accessor: 'role',
        width: 100,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => (
          <Box display="flex">
            <Box
              sx={{
                backgroundColor: value === Role.Developer ? 'indigo' : value === Role.Admin ? '#dc3545' : '#D1DADE',
                color: value === Role.Developer || value === Role.Admin ? '#fff' : '#5E5E5E',
                fontSize: '0.6875rem',
                fontWeight: 600,
                px: 1,
                py: 0.5,
                borderRadius: '0.25rem',
              }}
            >
              {value}
            </Box>
          </Box>
        ),
        Filter: RoleFilter,
      },
      {
        Header: 'Farba',
        accessor: 'color',
        width: 50,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => (
          <Box display="flex" alignItems="center">
            <Box
              component="span"
              sx={{
                width: '1em',
                height: '1em',
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginRight: '.5em',
                backgroundColor: `#${value ?? '000000'}`,
              }}
            />{' '}
            #{value ?? '000000'}
          </Box>
        ),
      },
      {
        Header: t(ADMIN_USERS_COLUMN_ACTIVE),
        accessor: 'active',
        width: 100,
        Filter: BooleanFilter,
        Cell: BooleanCell,
      },
    ],
    [t],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faUsers} /> {t(ADMIN_USERS_TITLE)}
          </>
        }
        actions={
          <Box display="flex" justifyContent="flex-end">
            <Button component={RouterLink} to={ADMIN_USERS_CREATE_ROUTE} startIcon={<Add />} variant="contained">
              {t(ADMIN_USERS_NEW_USER)}
            </Button>
          </Box>
        }
      />

      <PagePaper>
        <UsersReactTable
          id="admin-users"
          columns={columns}
          defaultSortBy={defaultSortBy}
          query={Users_Load}
          mapCount={mapCount}
          mapData={mapData}
          canPrint={false}
          canXLSXExport={false}
        />
      </PagePaper>
    </>
  );
};

export default authorized(Permission.UsersManage)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Používatelia',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs) => ({
    title: `Používatelia | Administrácia | ${meta.title}`,
  }),
};

interface SetActiveButtonOwnProps {
  className?: string;
  user: {
    id: string;
    updatedAt?: string;
    active?: boolean;
  };
}

const SetActiveButton: React.FunctionComponent<SetActiveButtonOwnProps> = ({ className, user }) => {
  const { t } = useLocale();
  const { enqueueSnackbar } = useSnackbar();
  const [setActiveExecute, { loading }] = useMutation<Users_SetActiveMutation, Users_SetActiveMutationVariables>(
    Users_SetActive,
    {
      variables: {
        id: user.id,
        updatedAt: user.updatedAt,
        active: !user.active,
      },
    },
  );

  const onClick = useEventCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const execute = async () => {
      try {
        await setActiveExecute();
      } catch (err) {
        displayError(err, enqueueSnackbar, logger);
      }
    };

    execute();
  });

  return (
    <Tooltip title={user.active ? t(TOOLTIP_DEACTIVATE) : t(TOOLTIP_ACTIVATE)} placement="top">
      <span>
        <IconButtonWithLoading
          sx={{ color: user.active ? 'danger.main' : 'success.main' }}
          size="small"
          onClick={onClick}
          loading={loading}
          className={className}
        >
          {user.active ? <LockOutlined /> : <LockOpenOutlined />}
        </IconButtonWithLoading>
      </span>
    </Tooltip>
  );
};

SetActiveButton.displayName = 'SetActiveButton';

const RoleFilter: FilterComponent<Users_UserFragment, SchemaStringFilter> = (props) => {
  const { requestUser } = useAuth();
  const canDevelop = requestUser.role === Role.Developer;

  const roleOptions = useMemo(
    (): Option[] =>
      Object.keys(Role)
        .filter((r) => canDevelop || Role[r] !== Role.Developer)
        .map((r) => ({
          label: Role[r],
          value: Role[r],
        })),
    [canDevelop],
  );

  return <SelectFilter {...props} options={roleOptions} />;
};

RoleFilter.displayName = 'RoleFilter';

RoleFilter.filterToGraphQLVariable = (value) => ({
  eq: value,
});
