import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { YES, NO } from '@engined/client/locales.js';
import { Check, Clear } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';

function BooleanCell({
  value,
  yesTooltip,
  noTooltip,
}: {
  value: boolean | string | number;
  yesTooltip?: string;
  noTooltip?: string;
}) {
  const { t } = useLocale();

  return (
    <CenteredCell sx={{ color: value ? 'success.main' : 'error.main' }}>
      {value ? (
        <Tooltip title={yesTooltip ?? t(YES)} placement="top">
          <Check />
        </Tooltip>
      ) : (
        <Tooltip title={noTooltip ?? t(NO)} placement="top">
          <Clear />
        </Tooltip>
      )}
    </CenteredCell>
  );
}

BooleanCell.displayName = 'BooleanCell';

export default BooleanCell;
