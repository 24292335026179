import { CircularProgress, IconButton as UIIconButton, IconButtonProps } from '@mui/material';
import React from 'react';

interface OwnProps {
  className?: string;
  loading?: boolean;
}

type Props = IconButtonProps & OwnProps;

const IconButtonWithLoading: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { loading, children, className, ...rest },
  ref,
) => {
  return (
    <UIIconButton
      {...rest}
      disabled={rest.disabled || loading}
      className={className}
      ref={ref}
      sx={{ position: 'relative' }}
    >
      {children}
      {loading && (
        <CircularProgress
          size={rest.size === 'small' ? 30 : 48}
          sx={{ color: '#4caf50', position: 'absolute', zIndex: 1 }}
        />
      )}
    </UIIconButton>
  );
};

IconButtonWithLoading.displayName = 'IconButtonWithLoading';

export default React.memo<Props>(React.forwardRef(IconButtonWithLoading));
